import type {MediaTypeLoadingStates, MediaTypePublishingParams, PostState, SocialMediaAccountId, SocialPost} from '@Components/social-media/post.vo';
import {PlatformMediaType} from '@Components/social-media/post.vo';
import {noop} from '@Utils/general.util';
import {useEffect, useRef, useState} from 'react';
import type {SocialMediaGridRequestThunkParams} from '@Components/social-media/social-media-thunk';
import {
  doPublishForAccount,
  getPostableSocialMediaAccountsForUser,
  getSocialMediaAccountsForUser,
  getSocialMediaPostsForUser,
  updateSocialMediaPostPublishingParams,
} from '@Components/social-media/social-media-thunk';
import {DEFAULT_POSTS_LIMIT, resetPosts, updateAccountSorter, updateDoRefetchGridForNewSorting, updatePostSorter} from '@Components/social-media/social-media-reducer';
import type {AccountState, SocialAccount} from '@Components/social-media/account.vo';
import type {
  AccountStateFilter,
  ContainerQueryParams,
  PostStateFilter,
  PublishedUrlMapForAccount,
  RetryStatusForMediaType,
  SocialPlatform,
  SocialPostGridContainerQuery,
} from '@Components/social-media/social-media.types';
import {socialGridContainerQuery, AccountSortingMetric, PostSortingMetric} from '@Components/social-media/social-media.types';
import type {ListItemStorage} from '@Components/list/list.types';
import {LIST_ITEM_TYPE} from '@Components/list/list.types';
import {getNonStoryPlatformMediaType, getSocialPlatformName, isPostVisibleForFilter} from '@Libraries/social-media';
import useDidUpdateEffect from '@Hooks/useDidUpdateEffect';
import {executeThunk} from '@Utils/thunk.util';
import type {ErrorResponseUpdatePublishingParams} from '@Components/social-media-wizard/social-media-wizard.types';
import {ErrorUpdatePublishingParams, SocialMediaWizardStep} from '@Components/social-media-wizard/social-media-wizard.types';
import type {SocialMediaPublishingParams} from '@Components/social-media/publish-params.types';
import {RetryStatus, SocialPostPublishingAction} from '@Components/social-media/publish-params.types';
import {LoadingStates} from '@Utils/loading.util';
import {
  unsetRetryingStatusForAccountWithMediaType,
  updatePublishedAccountPostUrlMapForAccountWithMediaType,
  updatePublishErrorMessageForAccount,
  updatePublishErrorMessageForAllParams,
  updatePublishingStatusForAccountWithMediaType,
  updatePublishingStatusForAllAccountMediaTypes,
  updateSavingPublishingParamsState,
} from '@Components/social-media-wizard/social-media-wizard-reducer';
import {GA4EventName, GA4EventParamName, trackPublishingGA4Events} from '@Libraries/ga-events';
import {AccountResizeStatus} from '@Components/social-media-wizard/components/social-media-resize-options/social-media-resize-options.types';
import {useDesignIdsToPublish} from '@Components/social-media-wizard/social-media-wizard.hooks';
import type {StoryGuideline} from '@Components/social-media-wizard/components/social-media-publish-params-story-guideline';
import {useContainerQuery} from '@Hooks/useContainerQuery';
import {useAppDispatch, useAppSelector} from '@/hooks';

export const useSocialMediaPosts = (): SocialPost[] => {
  return useAppSelector((state) => {
    return state.socialMediaGrid.posts;
  });
};

export const useSocialMediaHaveAccountsLoaded = (): boolean => {
  return useAppSelector((state) => {
    return state.socialMediaGrid.haveAccountsLoaded;
  });
};

export const useSocialMediaHavePostsLoaded = (): boolean => {
  return useAppSelector((state) => {
    return state.socialMediaGrid.havePostsLoaded;
  });
};

export const useDoRefetchGridForNewSorting = (): boolean => {
  return useAppSelector((state) => {
    return state.socialMediaGrid.doRefetchGridForNewSorting;
  });
};

export const useSocialMediaPostsOffset = (): number => {
  return useAppSelector((state) => {
    return state.socialMediaGrid.posts.length;
  });
};

export const useAccountStateFilter = (): AccountStateFilter => {
  return useAppSelector((state) => {
    return state.socialMediaGrid.accountStateFilter;
  });
};

export const useIsAccountVisibleForFilter = (accountState: AccountState): boolean => {
  return useAppSelector((state) => {
    return state.socialMediaGrid.accountStateFilter[accountState];
  });
};

export const useSocialPostGridContainerQuery = (): ContainerQueryParams => {
  const containerRef = useRef<HTMLDivElement>(null);
  const params = useContainerQuery(containerRef, socialGridContainerQuery);

  const containerQueryParamProps: SocialPostGridContainerQuery = {
    xsmall: params.xsmall,
    small: params.small,
    medium: params.medium,
    large: params.large,
    xlarge: params.xlarge,
    xxlarge: params.xxlarge,
  };

  return {
    containerQueryParamProps,
    containerRef,
    hasContainerQueryInitialized: params.small !== undefined,
  };
};

export const useIsPostVisibleForFilter = (postState: PostState): boolean => {
  const postStateFilter = useAppSelector((state) => {
    return state.socialMediaGrid.postStateFilter;
  });

  const [isVisible, setIsVisible] = useState(true);

  useDidUpdateEffect(() => {
    setIsVisible(isPostVisibleForFilter(postState, postStateFilter));
  }, [postStateFilter]);

  return isVisible;
};

export const usePostStateFilter = (): PostStateFilter => {
  return useAppSelector((state) => {
    return state.socialMediaGrid.postStateFilter;
  });
};

export const useSearchTerm = (): string => {
  return useAppSelector((state) => {
    return state.socialMediaGrid.searchTerm;
  });
};

export const usePostSorter = (): PostSortingMetric => {
  return useAppSelector((state) => {
    return state.socialMediaGrid.postSorter;
  });
};

export const useMorePostsOnServer = (): boolean => {
  return useAppSelector((state) => {
    return state.socialMediaGrid.morePostsOnServer;
  });
};

export const useGetDeletedPosts = (): boolean => {
  return useAppSelector((state) => {
    return state.socialMediaGrid.getDeletedPosts;
  });
};
export const useAccountSorter = (): AccountSortingMetric => {
  return useAppSelector((state) => {
    return state.socialMediaGrid.accountSorter;
  });
};

export const useSocialMediaAccounts = (): SocialAccount[] => {
  return useAppSelector((state) => {
    return state.socialMediaGrid.accounts;
  });
};

export const useLoadSocialAccountsForWizard = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    void executeThunk(() => {
      return dispatch(getPostableSocialMediaAccountsForUser());
    }).then(noop);
  }, []);
};

export const usePublishingStatusForAllAccounts = (): Record<SocialMediaAccountId, MediaTypeLoadingStates> => {
  return useAppSelector((state): Record<SocialMediaAccountId, MediaTypeLoadingStates> => {
    return state.socialMediaWizard.publishingStatusForMediaTypes;
  });
};

export const usePublishingStatusForAccountWithMediaType = (idSocialMediaAccount: SocialMediaAccountId, mediaType: PlatformMediaType): LoadingStates => {
  return useAppSelector((state) => {
    if (state.socialMediaWizard.publishingStatusForMediaTypes[idSocialMediaAccount] === undefined) {
      return LoadingStates.LOADING;
    }

    return state.socialMediaWizard.publishingStatusForMediaTypes[idSocialMediaAccount][mediaType];
  });
};

export const useRetryStatusForAccount = (idSocialMediaAccount: SocialMediaAccountId): RetryStatusForMediaType => {
  return useAppSelector((state): RetryStatusForMediaType => {
    return state.socialMediaWizard.retryPublishingMapForAccount[idSocialMediaAccount];
  });
};

export const useRetryPublishingPostOrReelForAccount = (idSocialMediaAccount: SocialMediaAccountId): void => {
  const dispatch = useAppDispatch();
  const retryStatusForAccount = useRetryStatusForAccount(idSocialMediaAccount);

  if (!retryStatusForAccount) {
    console.error('No retry status for account with id ', idSocialMediaAccount, ' found');
    return;
  }

  const post = useSocialMediaWizardPost();
  const publishingParams = usePublishingParams()[idSocialMediaAccount];

  if (!publishingParams) {
    console.error('No publishing params found for account with id: ', idSocialMediaAccount);
  }

  const platformMediaType = getNonStoryPlatformMediaType(publishingParams);

  if (!platformMediaType) {
    return;
  }

  const wizardAccounts = useSocialMediaWizardAccounts();
  const foundAccount = wizardAccounts.find((account) => {
    return account.id === idSocialMediaAccount;
  });
  const platform = foundAccount?.type;

  const onErrorRequest = (resetRetryStatusOnCompletion: () => void): void => {
    dispatch(
      updatePublishingStatusForAccountWithMediaType({
        idSocialMediaAccount,
        publishingStatus: LoadingStates.NOT_LOADED,
        platformMediaType,
      })
    );

    resetRetryStatusOnCompletion();
  };

  const resetRetryStatusOnCompletion = (): void => {
    dispatch(unsetRetryingStatusForAccountWithMediaType({accountId: idSocialMediaAccount, platformMediaType}));
  };

  const onRetrySuccessResponse = (data: object): void => {
    if (data && 'postUrl' in data) {
      dispatch(
        updatePublishedAccountPostUrlMapForAccountWithMediaType({
          accountId: idSocialMediaAccount,
          mediaType: platformMediaType,
          url: data.postUrl as string,
        })
      );
    }

    if (data && 'publishErrorMessage' in data) {
      const errorMessage: string | null = typeof data.publishErrorMessage === 'string' ? data.publishErrorMessage : null;
      dispatch(
        updatePublishErrorMessageForAccount({
          idSocialMediaAccount,
          publishErrorMessage: errorMessage,
          platformMediaType,
        })
      );

      if (errorMessage) {
        onErrorRequest(resetRetryStatusOnCompletion);
        return;
      }
    }

    if (platform) {
      const platformString = getSocialPlatformName(platform);
      trackPublishingGA4Events(GA4EventName.POST_PUBLISHED, {[GA4EventParamName.PLATFORM]: platformString});
    }

    dispatch(
      updatePublishingStatusForAccountWithMediaType({
        idSocialMediaAccount,
        publishingStatus: LoadingStates.LOADED,
        platformMediaType,
      })
    );
    resetRetryStatusOnCompletion();
  };

  useEffect(() => {
    const retryStatusForStories = retryStatusForAccount[platformMediaType];
    if (!retryStatusForStories) {
      return;
    }

    if (retryStatusForStories !== RetryStatus.RETRYING) {
      return;
    }

    void executeThunk(
      () => {
        dispatch(
          updatePublishingStatusForAccountWithMediaType({
            idSocialMediaAccount,
            publishingStatus: LoadingStates.LOADING,
            platformMediaType,
          })
        );

        return dispatch(
          doPublishForAccount({
            idPost: post ? post.id : null,
            idSocialMediaAccount,
            isRetry: true,
            mediaType: platformMediaType,
          })
        );
      },
      (data: object) => {
        onRetrySuccessResponse(data);
      },
      () => {
        onErrorRequest(resetRetryStatusOnCompletion);
      }
    );
  }, [retryStatusForAccount]);
};

export const useRetryPublishingStoryForAccount = (idSocialMediaAccount: SocialMediaAccountId): void => {
  const dispatch = useAppDispatch();
  const retryStatusForAccount = useRetryStatusForAccount(idSocialMediaAccount);

  if (!retryStatusForAccount) {
    console.error('No retry status for account with id ', idSocialMediaAccount, ' found');
    return;
  }

  const post = useSocialMediaWizardPost();
  const wizardAccounts = useSocialMediaWizardAccounts();
  const foundAccount = wizardAccounts.find((account) => {
    return account.id === idSocialMediaAccount;
  });
  const platform = foundAccount?.type;

  const onErrorRequest = (resetRetryStatusOnCompletion: () => void): void => {
    dispatch(
      updatePublishingStatusForAccountWithMediaType({
        idSocialMediaAccount,
        publishingStatus: LoadingStates.NOT_LOADED,
        platformMediaType: PlatformMediaType.STORIES,
      })
    );

    resetRetryStatusOnCompletion();
  };

  useEffect(() => {
    const retryStatusForStories = retryStatusForAccount[PlatformMediaType.STORIES];
    if (!retryStatusForStories) {
      return;
    }

    if (retryStatusForStories !== RetryStatus.RETRYING) {
      return;
    }

    const resetRetryStatusOnCompletion = (): void => {
      dispatch(unsetRetryingStatusForAccountWithMediaType({accountId: idSocialMediaAccount, platformMediaType: PlatformMediaType.STORIES}));
    };

    void executeThunk(
      () => {
        dispatch(
          updatePublishingStatusForAccountWithMediaType({
            idSocialMediaAccount,
            publishingStatus: LoadingStates.LOADING,
            platformMediaType: PlatformMediaType.STORIES,
          })
        );

        return dispatch(
          doPublishForAccount({
            idPost: post ? post.id : null,
            idSocialMediaAccount,
            isRetry: true,
            mediaType: PlatformMediaType.STORIES,
          })
        );
      },
      (data: object) => {
        if (data && 'postUrl' in data) {
          dispatch(
            updatePublishedAccountPostUrlMapForAccountWithMediaType({
              accountId: idSocialMediaAccount,
              mediaType: PlatformMediaType.STORIES,
              url: data.postUrl as string,
            })
          );
        }

        if (data && 'publishErrorMessage' in data) {
          const errorMessage: string | null = typeof data.publishErrorMessage === 'string' ? data.publishErrorMessage : null;
          dispatch(
            updatePublishErrorMessageForAccount({
              idSocialMediaAccount,
              publishErrorMessage: errorMessage,
              platformMediaType: PlatformMediaType.STORIES,
            })
          );

          if (errorMessage) {
            onErrorRequest(resetRetryStatusOnCompletion);
            return;
          }
        }

        if (platform) {
          const platformString = getSocialPlatformName(platform);
          const platformNameForGA = `${platformString}_${PlatformMediaType.STORIES}`;
          trackPublishingGA4Events(GA4EventName.POST_PUBLISHED, {[GA4EventParamName.PLATFORM]: platformNameForGA});
        }

        dispatch(
          updatePublishingStatusForAccountWithMediaType({
            idSocialMediaAccount,
            publishingStatus: LoadingStates.LOADED,
            platformMediaType: PlatformMediaType.STORIES,
          })
        );
        resetRetryStatusOnCompletion();
      },
      () => {
        onErrorRequest(resetRetryStatusOnCompletion);
      }
    );
  }, [retryStatusForAccount]);
};

// Note : Reel and Post can be coupled under the same hook because only either of them will be published in ALL cases.
// i.e - There will be no case that a Reel and Post can co-exist and be published together (as opposed to Stories)
export const useStartPublishingReelOrPostForAccount = (idSocialMediaAccount: SocialMediaAccountId, platformMediaType: PlatformMediaType): void => {
  const dispatch = useAppDispatch();
  const savingPublishingParamsState = useSavingPublishingParamsState();
  const publishingAction = useSocialPostPublishingAction();
  const publishingParams = usePublishingParams();

  if (platformMediaType === PlatformMediaType.STORIES) {
    return;
  }

  if (!publishingParams[idSocialMediaAccount][platformMediaType]) {
    console.error('No Publishing Params found for account with id: ', idSocialMediaAccount, ' and platform media type: ', platformMediaType);
    return;
  }

  const publishingState = usePublishingStatusForAccountWithMediaType(idSocialMediaAccount, platformMediaType);
  const post = useSocialMediaWizardPost();
  const wizardAccounts = useSocialMediaWizardAccounts();

  const foundAccount = wizardAccounts.find((account) => {
    return account.id === idSocialMediaAccount;
  });

  const platform = foundAccount?.type;

  const onErrorRequest = (): void => {
    dispatch(
      updatePublishedAccountPostUrlMapForAccountWithMediaType({
        accountId: idSocialMediaAccount,
        mediaType: platformMediaType,
        url: '',
      })
    );

    dispatch(
      updatePublishingStatusForAccountWithMediaType({
        idSocialMediaAccount,
        publishingStatus: LoadingStates.NOT_LOADED,
        platformMediaType,
      })
    );
  };

  useEffect(() => {
    if (publishingState === LoadingStates.LOADED || publishingState === LoadingStates.NOT_LOADED) {
      return;
    }

    if (savingPublishingParamsState !== LoadingStates.LOADED) {
      return;
    }

    if (publishingAction !== SocialPostPublishingAction.PUBLISH) {
      return;
    }

    void executeThunk(
      () => {
        dispatch(
          updatePublishingStatusForAccountWithMediaType({
            idSocialMediaAccount,
            publishingStatus: LoadingStates.LOADING,
            platformMediaType,
          })
        );

        return dispatch(
          doPublishForAccount({
            idPost: post ? post.id : null,
            idSocialMediaAccount,
            mediaType: platformMediaType,
            isRetry: false,
          })
        );
      },
      (data: object) => {
        if (data && 'postUrl' in data) {
          dispatch(
            updatePublishedAccountPostUrlMapForAccountWithMediaType({
              accountId: idSocialMediaAccount,
              mediaType: platformMediaType,
              url: data.postUrl as string,
            })
          );
        }

        if (data && 'publishErrorMessage' in data) {
          const errorMessage: string | null = typeof data.publishErrorMessage === 'string' ? data.publishErrorMessage : null;
          dispatch(
            updatePublishErrorMessageForAccount({
              idSocialMediaAccount,
              publishErrorMessage: errorMessage,
              platformMediaType,
            })
          );

          if (errorMessage) {
            onErrorRequest();
            return;
          }
        }

        if (platform) {
          const platformString = getSocialPlatformName(platform);
          trackPublishingGA4Events(GA4EventName.POST_PUBLISHED, {[GA4EventParamName.PLATFORM]: platformString});
        }

        dispatch(
          updatePublishingStatusForAccountWithMediaType({
            idSocialMediaAccount,
            publishingStatus: LoadingStates.LOADED,
            platformMediaType,
          })
        );
      },
      () => {
        onErrorRequest();
      }
    );
  }, [savingPublishingParamsState]);
};

export const useStartPublishingStoryForAccount = (idSocialMediaAccount: SocialMediaAccountId, platformMediaType: PlatformMediaType): void => {
  const dispatch = useAppDispatch();
  const savingPublishingParamsState = useSavingPublishingParamsState();
  const publishingAction = useSocialPostPublishingAction();
  const publishingState = usePublishingStatusForAccountWithMediaType(idSocialMediaAccount, PlatformMediaType.STORIES);
  const post = useSocialMediaWizardPost();
  const wizardAccounts = useSocialMediaWizardAccounts();

  if (platformMediaType !== PlatformMediaType.STORIES) {
    return;
  }

  const foundAccount = wizardAccounts.find((account) => {
    return account.id === idSocialMediaAccount;
  });

  const platform = foundAccount?.type;

  const onErrorRequest = (): void => {
    dispatch(
      updatePublishedAccountPostUrlMapForAccountWithMediaType({
        accountId: idSocialMediaAccount,
        mediaType: PlatformMediaType.STORIES,
        url: '',
      })
    );

    dispatch(
      updatePublishingStatusForAccountWithMediaType({
        idSocialMediaAccount,
        publishingStatus: LoadingStates.NOT_LOADED,
        platformMediaType: PlatformMediaType.STORIES,
      })
    );
  };

  useEffect(() => {
    if (publishingState === LoadingStates.LOADED || publishingState === LoadingStates.NOT_LOADED) {
      return;
    }

    if (savingPublishingParamsState !== LoadingStates.LOADED) {
      return;
    }

    if (publishingAction !== SocialPostPublishingAction.PUBLISH) {
      return;
    }

    void executeThunk(
      () => {
        dispatch(
          updatePublishingStatusForAccountWithMediaType({
            idSocialMediaAccount,
            publishingStatus: LoadingStates.LOADING,
            platformMediaType: PlatformMediaType.STORIES,
          })
        );

        return dispatch(
          doPublishForAccount({
            idPost: post ? post.id : null,
            idSocialMediaAccount,
            mediaType: PlatformMediaType.STORIES,
            isRetry: false,
          })
        );
      },
      (data: object) => {
        if (data && 'postUrl' in data) {
          dispatch(
            updatePublishedAccountPostUrlMapForAccountWithMediaType({
              accountId: idSocialMediaAccount,
              mediaType: PlatformMediaType.STORIES,
              url: data.postUrl as string,
            })
          );
        }

        if (data && 'publishErrorMessage' in data) {
          const errorMessage: string | null = typeof data.publishErrorMessage === 'string' ? data.publishErrorMessage : null;
          dispatch(
            updatePublishErrorMessageForAccount({
              idSocialMediaAccount,
              publishErrorMessage: errorMessage,
              platformMediaType: PlatformMediaType.STORIES,
            })
          );

          if (errorMessage) {
            onErrorRequest();
            return;
          }
        }

        if (platform) {
          const platformString = getSocialPlatformName(platform);
          const platformNameForGA = `${platformString}_${PlatformMediaType.STORIES}`;
          trackPublishingGA4Events(GA4EventName.POST_PUBLISHED, {[GA4EventParamName.PLATFORM]: platformNameForGA});
        }

        dispatch(
          updatePublishingStatusForAccountWithMediaType({
            idSocialMediaAccount,
            publishingStatus: LoadingStates.LOADED,
            platformMediaType: PlatformMediaType.STORIES,
          })
        );
      },
      () => {
        onErrorRequest();
      }
    );
  }, [savingPublishingParamsState]);
};

export const useUpdatePublishingParams = (): void => {
  const dispatch = useAppDispatch();
  const post = useSocialMediaWizardPost();
  const publishingAction = useSocialPostPublishingAction();
  const step = useWizardStep();
  const designIds = useDesignIdsToPublish();
  const publishingParams = usePublishingParams();
  const accountIdsToPublishTo = useAccountIdsToPublishTo();

  const updatePublishingStatusForSaveOrSchedule = (loadingState: LoadingStates): void => {
    if (publishingAction !== SocialPostPublishingAction.PUBLISH) {
      dispatch(updatePublishingStatusForAllAccountMediaTypes(loadingState));
    }
  };

  const fireMultiPlatformPublishEvent = (): void => {
    if (!post || publishingAction !== SocialPostPublishingAction.PUBLISH) {
      return;
    }

    const numPlatforms = accountIdsToPublishTo.length;
    trackPublishingGA4Events(GA4EventName.SOCIALWIZARD_NUMPLATFORMS, {[GA4EventParamName.NUM_PLATFORMS]: `${numPlatforms}`});
  };

  const fireScheduleTrackingEvent = (): void => {
    if (!post) {
      return;
    }

    if (publishingAction !== SocialPostPublishingAction.SCHEDULE) {
      return;
    }

    const {accounts} = post;

    for (let i = 0; i < accounts.length; i++) {
      const platform = accounts[i].type;
      const platformName = getSocialPlatformName(platform);

      const doesStoryExistForAccount = publishingParams[accounts[i].id].STORIES !== null;

      if (doesStoryExistForAccount) {
        const storyPlatformForGA = `${platformName}_${PlatformMediaType.STORIES}`;
        trackPublishingGA4Events(GA4EventName.POST_SCHEDULED, {[GA4EventParamName.PLATFORM]: storyPlatformForGA});
      }

      trackPublishingGA4Events(GA4EventName.POST_SCHEDULED, {[GA4EventParamName.PLATFORM]: platformName});
    }
  };

  const getSanitizedTimeZoneOffset = (): null | number => {
    let timeZoneOffset = null;
    if (post && post.timeZoneOffset !== null && post.timeZoneOffset !== undefined) {
      timeZoneOffset = post.timeZoneOffset;
    }
    return timeZoneOffset;
  };

  useEffect(() => {
    if (step === SocialMediaWizardStep.PUBLISH) {
      void executeThunk(
        () => {
          updatePublishingStatusForSaveOrSchedule(LoadingStates.LOADING);
          dispatch(updateSavingPublishingParamsState(LoadingStates.LOADING));
          const publishOn: number | undefined = post ? post.publishOn : undefined;
          const timeZoneOffset: number | null = getSanitizedTimeZoneOffset();

          return dispatch(
            updateSocialMediaPostPublishingParams({
              idPost: post ? post.id : null,
              idDesigns: designIds || null,
              publishOn: publishOn || null,
              timeZoneOffset,
              publishingParams,
            })
          );
        },
        () => {
          fireScheduleTrackingEvent();
          fireMultiPlatformPublishEvent();
          updatePublishingStatusForSaveOrSchedule(LoadingStates.LOADED);
          dispatch(updateSavingPublishingParamsState(LoadingStates.LOADED));
        },
        ({error}: ErrorResponseUpdatePublishingParams) => {
          if (error.code === ErrorUpdatePublishingParams.RATE_LIMITED && error.message !== '' && accountIdsToPublishTo.length > 0) {
            dispatch(updatePublishErrorMessageForAllParams(error.message));
          }

          if (error.code === ErrorUpdatePublishingParams.ALREADY_PUBLISHED) {
            return;
          }

          fireMultiPlatformPublishEvent();
          updatePublishingStatusForSaveOrSchedule(LoadingStates.NOT_LOADED);
          dispatch(updateSavingPublishingParamsState(LoadingStates.NOT_LOADED));
        }
      );
    }
  }, [step]);
};

export const useSocialMediaWizardPost = (): SocialPost | undefined => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.post;
  });
};

export const useSocialPostPublishingAction = (): SocialPostPublishingAction | undefined => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.publishingAction;
  });
};

export const useSocialMediaWizardAccounts = (): SocialAccount[] => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.accounts;
  });
};

export const useHasCommonCaption = (): boolean => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.hasCommonCaption;
  });
};

export const useHasCommonPostCover = (): boolean => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.hasCommonPostCover;
  });
};

export const useCommonCaption = (): string => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.commonCaption;
  });
};

export const useTriggerNext = (): number => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.triggerNext;
  });
};

export const useTriggerPrevious = (): number => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.triggerPrevious;
  });
};

export const useAccountIdsToPublishTo = (): number[] => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.accountIdsToPublishTo;
  });
};

export const usePostPublishingParamsForAccount = (socialMediaAccountId: SocialMediaAccountId): SocialMediaPublishingParams | null => {
  return useAppSelector((state) => {
    if (!state.socialMediaWizard.publishingParams[socialMediaAccountId]) {
      return null;
    }

    return state.socialMediaWizard.publishingParams[socialMediaAccountId].POST;
  });
};

export const useReelPublishingParamsForAccount = (socialMediaAccountId: SocialMediaAccountId): SocialMediaPublishingParams | null => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.publishingParams[socialMediaAccountId].REELS;
  });
};

export const useStoryPublishingParamsForAccount = (socialMediaAccountId: SocialMediaAccountId): SocialMediaPublishingParams | null => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.publishingParams[socialMediaAccountId].STORIES;
  });
};

export const useAllPublishingParamsForAccount = (socialMediaAccountId: SocialMediaAccountId): MediaTypePublishingParams => {
  return useAppSelector((state): MediaTypePublishingParams => {
    return state.socialMediaWizard.publishingParams[socialMediaAccountId];
  });
};

export const useStoryGuidelineForAccount = (accountId: number): StoryGuideline => {
  return useAppSelector((state): StoryGuideline => {
    return state.socialMediaWizard.storyGuidelineForAccount[accountId];
  });
};

export const usePublishingParams = (): Record<SocialMediaAccountId, MediaTypePublishingParams> => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.publishingParams;
  });
};

export const useDesignIdToResize = (): string | undefined => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.designIdToResize;
  });
};

export const useAccountTypeToConnect = (): SocialPlatform | undefined => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.accountTypeToConnect;
  });
};

export const useNextButtonIcon = (): string => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.nextButtonIcon;
  });
};

export const useNextButtonText = (): string => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.nextButtonText;
  });
};

export const useNextButtonDisabled = (): boolean => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.nextButtonDisabled;
  });
};

export const useSavingPublishingParamsState = (): LoadingStates => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.savingPublishingParamsState;
  });
};

export const useWizardStep = (): SocialMediaWizardStep => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.wizardStep;
  });
};

export const useLoadSocialAccounts = (): void => {
  const haveAccountsLoaded = useSocialMediaHaveAccountsLoaded();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (haveAccountsLoaded) {
      return;
    }

    void executeThunk(() => {
      return dispatch(getSocialMediaAccountsForUser());
    }).then(noop);
  }, []);
};

export const useReloadGridForSorterIfNeeded = (): void => {
  const sortPostsBy = usePostSorter();
  const morePostsOnServer = useMorePostsOnServer();
  const numPostsLoaded = useSocialMediaPostsOffset();
  const dispatch = useAppDispatch();

  useDidUpdateEffect(() => {
    if (!morePostsOnServer) {
      return;
    }

    dispatch(updateDoRefetchGridForNewSorting(true));
    dispatch(resetPosts());
    const limit = numPostsLoaded;
    const offset = 0;
    const getDeleted = false;

    const args: SocialMediaGridRequestThunkParams = {
      limit,
      offset,
      getDeleted,
      sortPostsBy,
    };

    void executeThunk(() => {
      return dispatch(getSocialMediaPostsForUser(args));
    }).then();
  }, [sortPostsBy]);
};
export const useLoadSocialPosts = (getDeleted: boolean): void => {
  const dispatch = useAppDispatch();
  const offset = useSocialMediaPostsOffset();
  const doRefetchGridForNewSorting = useDoRefetchGridForNewSorting();
  const havePostsLoaded = useSocialMediaHavePostsLoaded();
  const sortPostsBy = usePostSorter();
  const limit = DEFAULT_POSTS_LIMIT;

  const getPostsFromServer = async (): Promise<void> => {
    const args: SocialMediaGridRequestThunkParams = {
      limit,
      offset,
      getDeleted,
      sortPostsBy,
    };

    await executeThunk(() => {
      return dispatch(getSocialMediaPostsForUser(args));
    });
  };

  useEffect(() => {
    if (havePostsLoaded || doRefetchGridForNewSorting) {
      return;
    }

    void getPostsFromServer().then(noop);
  }, [havePostsLoaded]);
};

export const useLazyLoadPostsFromServer = (lazyloadFunction: VoidFunction): void => {
  const DEBOUNCE_DELAY = 20;
  const morePostsOnServer = useMorePostsOnServer();

  useEffect(() => {
    window.addEventListener('scroll', doLazyloadIfNeeded, {passive: true});

    return () => {
      window.removeEventListener('scroll', doLazyloadIfNeeded);
    };
  }, [morePostsOnServer]);

  const doLazyloadIfNeeded = window.PMW.debounce(() => {
    if (!morePostsOnServer) {
      return;
    }

    const tar = document.getElementById('js-social-posts-grid');

    if (tar) {
      const bottom = tar.getBoundingClientRect().bottom <= window.innerHeight;
      if (bottom) {
        lazyloadFunction();
      }
    }
  }, DEBOUNCE_DELAY) as VoidFunction;
};

export const useAccountsSorterListItems = (): ListItemStorage[] => {
  const dispatch = useAppDispatch();

  const accountSorter = useAccountSorter();
  return [
    {
      id: AccountSortingMetric.DATE_CREATED as string,
      isSelected: accountSorter === AccountSortingMetric.DATE_CREATED,
      type: LIST_ITEM_TYPE.DEFAULT_3,
      text: window.i18next.t('pmwjs_date_added'),
      onClick(listId?: string, listItemId?: string): void {
        if (listItemId === (AccountSortingMetric.DATE_CREATED as string)) {
          dispatch(updateAccountSorter(AccountSortingMetric.DATE_CREATED));
        }
      },
    },
    {
      id: AccountSortingMetric.BY_PLATFORM as string,
      isSelected: accountSorter === AccountSortingMetric.BY_PLATFORM,
      type: LIST_ITEM_TYPE.DEFAULT_3,
      text: window.i18next.t('pmwjs_platform'),
      onClick(listId?: string, listItemId?: string): void {
        if (listItemId === (AccountSortingMetric.BY_PLATFORM as string)) {
          dispatch(updateAccountSorter(AccountSortingMetric.BY_PLATFORM));
        }
      },
    },
  ];
};
export const usePostsSorterListItems = (): ListItemStorage[] => {
  const dispatch = useAppDispatch();

  const postSorter = usePostSorter();
  return [
    {
      id: PostSortingMetric.LAST_MODIFIED as string,
      isSelected: postSorter === PostSortingMetric.LAST_MODIFIED,
      type: LIST_ITEM_TYPE.DEFAULT_3,
      text: window.i18next.t('pmwjs_last_modified'),
      onClick(listId?: string, listItemId?: string): void {
        if (listItemId === (PostSortingMetric.LAST_MODIFIED as string)) {
          dispatch(updatePostSorter(PostSortingMetric.LAST_MODIFIED));
        }
      },
    },
    {
      id: PostSortingMetric.DATE_PUBLISHED as string,
      isSelected: postSorter === PostSortingMetric.DATE_PUBLISHED,
      type: LIST_ITEM_TYPE.DEFAULT_3,
      text: window.i18next.t('pmwjs_publishing_date'),
      onClick(listId?: string, listItemId?: string): void {
        if (listItemId === (PostSortingMetric.DATE_PUBLISHED as string)) {
          dispatch(updatePostSorter(PostSortingMetric.DATE_PUBLISHED));
        }
      },
    },
  ];
};

export const useAccountResizeCompletedStatusForPosters = (account: SocialAccount | undefined, platformMediaType: PlatformMediaType): AccountResizeStatus => {
  return useAppSelector((state): AccountResizeStatus => {
    if (!account) {
      return AccountResizeStatus.NOT_RESIZED;
    }

    const resizingStatusForDesigns = state.socialMediaWizard.accountResizeStatusForMediaType[account.id][platformMediaType];

    if (!resizingStatusForDesigns) {
      return AccountResizeStatus.NOT_RESIZED;
    }

    for (const [posterId, resizeInformation] of Object.entries(resizingStatusForDesigns)) {
      if (resizeInformation !== AccountResizeStatus.RESIZE_DONE) {
        return resizeInformation;
      }
    }

    return AccountResizeStatus.RESIZE_DONE;
  });
};

export const usePublishedAccountUrlMap = (): PublishedUrlMapForAccount => {
  return useAppSelector((state): PublishedUrlMapForAccount => {
    return state.socialMediaWizard.publishedAccountUrlMapForMediaType;
  });
};

export const useHasAccounts = (): boolean => {
  return useAppSelector((state) => {
    return state.socialMediaGrid.accounts.length > 0;
  });
};
