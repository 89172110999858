import {SocialPlatform} from '@Components/social-media/social-media.types';
import type {SocialPost} from '@Components/social-media/post.vo';
import {PlatformMediaType} from '@Components/social-media/post.vo';

export interface SocialAccount {
  id: number;
  type: SocialPlatform;
  tikTokMeta: TikTokMeta | null;
  name: string;
  isPreferred: boolean;
  username: string;
  state: AccountState;
  isAccessTokenExpired: boolean;
  isVerifiedTwitterUser: boolean;
  isVerifiedGoogleBusinessProfileAccount: boolean;
  displayURL: string | null;
  isRefreshTokenValid: boolean;
  activityType: SocialAccountActivityType;
  metricsStatus: MetricsStatus;
  originalId: string;
}

export const MaxPostersPerPlatform: Record<SocialPlatform, number | null> = {
  [SocialPlatform.TWITTER]: 4,
  [SocialPlatform.FACEBOOK_GROUP]: null,
  [SocialPlatform.FACEBOOK_PROFILE]: null,
  [SocialPlatform.FACEBOOK_PAGE]: null,
  [SocialPlatform.FACEBOOK]: null,
  [SocialPlatform.YOUTUBE]: 1,
  [SocialPlatform.LINKEDIN]: 1,
  [SocialPlatform.LINKEDIN_PAGE]: 1,
  [SocialPlatform.LINKEDIN_PROFILE]: 1,
  [SocialPlatform.INSTAGRAM]: 10,
  [SocialPlatform.TIKTOK]: null,
  [SocialPlatform.PINTEREST]: null,
  [SocialPlatform.GOOGLE_BUSINESS_PROFILE]: 1,
  [SocialPlatform.THREADS]: 10,
};

export enum MetricsStatus {
  UNSUPPORTED = 'UNSUPPORTED',
  PERMISSION_PENDING = 'PERMISSION_PENDING',
  AVAILABLE = 'AVAILABLE',
}

export interface TikTokMeta {
  isCommentDisabled: boolean;
  isStitchDisabled: boolean;
  isDuetDisabled: boolean;
  isPublicAccount: boolean;
  maxVideoDurationSec: number;
}

export interface AccountMediaTypeInformation {
  platformMediaType: PlatformMediaType;
  account: SocialAccount;
}

export enum SocialAccountActivityType {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ABANDONED = 'ABANDONED',
}

export const getDefaultDisplayURL = (): string => {
  return window.PMW.getDefaultSmallProfilePictureUrl() as string;
};

export const hasExpired = (state: AccountState): boolean => {
  return state === AccountState.EXPIRED;
};

export const getDisplayableState = (state: AccountState): string => {
  switch (state) {
    case AccountState.EXPIRED:
      return window.i18next.t('pmwjs_connection_expired');
    case AccountState.CONNECTED:
    default:
      return window.i18next.t('pmwjs_connected');
  }
};

export function isFacebookAccount(socialAccount: SocialAccount | undefined): boolean {
  if (socialAccount === undefined) {
    return false;
  }

  return (
    socialAccount.type === SocialPlatform.FACEBOOK_GROUP ||
    socialAccount.type === SocialPlatform.FACEBOOK_PAGE ||
    socialAccount.type === SocialPlatform.FACEBOOK_PROFILE ||
    socialAccount.type === SocialPlatform.FACEBOOK
  );
}

export function isInstagramAccount(socialAccount: SocialAccount | undefined): boolean {
  if (socialAccount === undefined) {
    return false;
  }

  return socialAccount.type === SocialPlatform.INSTAGRAM;
}

export function isTikTokAccount(socialAccount: SocialAccount | undefined): boolean {
  if (socialAccount === undefined) {
    return false;
  }

  return socialAccount.type === SocialPlatform.TIKTOK;
}

export function isPinterestAccount(socialAccount: SocialAccount | undefined): boolean {
  if (socialAccount === undefined) {
    return false;
  }

  return socialAccount.type === SocialPlatform.PINTEREST;
}

export function isYoutubeAccount(socialAccount: SocialAccount | undefined): boolean {
  if (socialAccount === undefined) {
    return false;
  }

  return socialAccount.type === SocialPlatform.YOUTUBE;
}

export function isTwitterAccount(socialAccount: SocialAccount | undefined): boolean {
  if (socialAccount === undefined) {
    return false;
  }

  return socialAccount.type === SocialPlatform.TWITTER;
}

export function isLinkedinAccount(socialAccount: SocialAccount | undefined): boolean {
  if (socialAccount === undefined) {
    return false;
  }

  return socialAccount.type === SocialPlatform.LINKEDIN_PROFILE || socialAccount.type === SocialPlatform.LINKEDIN || socialAccount.type === SocialPlatform.LINKEDIN_PAGE;
}

export function isGoogleBusinessProfileAccount(socialAccount: SocialAccount | undefined): boolean {
  if (socialAccount === undefined) {
    return false;
  }

  return socialAccount.type === SocialPlatform.GOOGLE_BUSINESS_PROFILE;
}

export function isThreadsAccount(socialAccount: SocialAccount | undefined): boolean {
  if (socialAccount === undefined) {
    return false;
  }

  return socialAccount.type === SocialPlatform.THREADS;
}

export type PlatformMap = {
  [key in SocialPlatform]: boolean;
};

const updateUniqueMediaTypesArrayIfNeeded = (
  accountMediaTypeInformation: AccountMediaTypeInformation[],
  platformMediaType: PlatformMediaType,
  account: SocialAccount
): AccountMediaTypeInformation[] => {
  const alreadyExists = accountMediaTypeInformation.some((info) => {
    let areFacebookAccounts = false;
    if (isFacebookAccount(info.account) && isFacebookAccount(account)) {
      areFacebookAccounts = true;
    }

    const areBothPostOrReel =
      (info.platformMediaType === PlatformMediaType.POST || info.platformMediaType === PlatformMediaType.REELS) &&
      (platformMediaType === PlatformMediaType.POST || platformMediaType === PlatformMediaType.REELS);

    const areBothStories = info.platformMediaType === PlatformMediaType.STORIES && platformMediaType === PlatformMediaType.STORIES;

    return (info.account.type === account.type || areFacebookAccounts) && (areBothPostOrReel || areBothStories);
  });

  if (!alreadyExists) {
    accountMediaTypeInformation.push({
      account,
      platformMediaType,
    });
  }

  return accountMediaTypeInformation;
};

export const getUniqueAccountMediaTypeCombinations = (socialPost: SocialPost): AccountMediaTypeInformation[] => {
  let accountMediaTypeInformation: AccountMediaTypeInformation[] = [];
  const {accounts} = socialPost;

  for (let i = 0; i < socialPost.accounts.length; i++) {
    const allPublishingParamsForAccount = socialPost.publishingParams[accounts[i].id];

    if (allPublishingParamsForAccount[PlatformMediaType.POST] || allPublishingParamsForAccount[PlatformMediaType.REELS]) {
      const isPost = allPublishingParamsForAccount[PlatformMediaType.POST] !== null;
      const mediaType = isPost ? PlatformMediaType.POST : PlatformMediaType.REELS;
      accountMediaTypeInformation = updateUniqueMediaTypesArrayIfNeeded(accountMediaTypeInformation, mediaType, accounts[i]);
    }

    if (allPublishingParamsForAccount[PlatformMediaType.STORIES]) {
      accountMediaTypeInformation = updateUniqueMediaTypesArrayIfNeeded(accountMediaTypeInformation, PlatformMediaType.STORIES, accounts[i]);
    }
  }

  return accountMediaTypeInformation;
};

export enum AccountState {
  EXPIRED = 'EXPIRED',
  CONNECTED = 'CONNECTED',
}
