import type {MouseEventHandler, ReactElement, Ref} from 'react';
import React, {useState} from 'react';
import {IconSize} from '@Components/icon-v2/icon.types';
import {SearchBarTypes} from '@Components/search-bar/search-bar.types';
import {noop} from '@Utils/general.util';
import {ClickableDiv} from '@Components/clickable-div';
import styles from './search-bar.module.scss';
import {INPUT_FIELD_TYPE, InputField} from '../input-field';
import {Icon} from '../icon-v2';

export enum SearchBarSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

interface SearchBarProps {
  className?: string;
  allowEmptySearch?: boolean;
  fixSearchIcon?: boolean;
  ariaLabel?: string;
  placeholder?: string;
  onInput?: Function;
  onBlur?: Function;
  onClickIcon?: Function;
  onFocus?: Function;
  onSubmit?: MouseEventHandler;
  dropdown?: JSX.Element | null;
  initialValue?: string;
  isExpandedView?: boolean;
  searchBarType?: SearchBarTypes;
  iconSize?: IconSize;
  size?: SearchBarSize;
}

export const SearchBar = React.forwardRef<HTMLInputElement, SearchBarProps>(
  (
    {
      className = '',
      fixSearchIcon = false,
      onInput = noop,
      allowEmptySearch = false,
      initialValue = '',
      searchBarType = SearchBarTypes.NONE,
      iconSize = IconSize.SIZE_ICON_20,
      size = SearchBarSize.MEDIUM,
      ...props
    }: SearchBarProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const [searchDisabled, setSearchDisabled] = useState(allowEmptySearch);
    const [isSearching, setIsSearching] = useState<boolean>(false);

    const onInputChange = (txt: string): void => {
      setSearchDisabled(allowEmptySearch ? false : !txt);
      setIsSearching(!!txt.length);
      onInput(txt);
    };

    const isExpandableType = (): boolean => {
      return searchBarType === SearchBarTypes.EXPANDABLE_SEARCH;
    };

    const getSearchIcon = (): ReactElement | null => {
      if (!isLarge() && (fixSearchIcon || !isSearching)) {
        return (
          <ClickableDiv
            className={`${styles.searchIconContainer} ${isSearching ? styles.iconOnSearching : ''}${searchDisabled ? styles.disabledIcon : ''} `}
            onClick={props.onSubmit as VoidFunction}
          >
            <Icon className="content-sub-text" isDisabled={searchDisabled} size={iconSize} icon="icon-search" />
          </ClickableDiv>
        );
      }
      return null;
    };

    const isLarge = (): boolean => {
      return size === SearchBarSize.LARGE;
    };

    const getClassesForSize = (): string => {
      switch (size) {
        case SearchBarSize.SMALL:
          return styles.smallSize;
        case SearchBarSize.MEDIUM:
          return styles.mediumSize;
        default:
          return '';
      }
    };

    const getInputFieldWidth = (): string => {
      if (isExpandableType() && !isLarge()) {
        return styles.mediumWidthInput;
      }
      if (!isLarge() && !fixSearchIcon) {
        return styles.fullWidthInput;
      }
      return styles.smallWidthInput;
    };

    const getInputFieldMarkup = (): ReactElement => {
      const ariaLabel = props.ariaLabel ?? window.i18next.t('pmwjs_search');
      const placeholder = props.placeholder ?? window.i18next.t('pmwjs_start_search');
      return (
        <div
          className={`${styles.searchBar} ${props.dropdown ? styles.withDropdown : ''} ${!isLarge() && !isExpandableType() ? styles.smallSearchBar : ''} ${
            isExpandableType() ? styles.focusState : ''
          }`}
        >
          <InputField
            type={INPUT_FIELD_TYPE.TEXT}
            aria-label={ariaLabel}
            placeholder={placeholder}
            className={`${isLarge() ? styles.bigInputContainer : styles.smallInputContainer} no-border ${getInputFieldWidth()}`}
            ref={ref}
            inputIconExtraClass={`${styles.clearSearchBtn}`}
            onInputChange={onInputChange}
            onInputBlur={props.onBlur}
            onInputFocus={props.onFocus}
            onInputSubmit={props.onSubmit}
            submitOnEnter
            showInputIcon={!isLarge() && !isExpandableType()}
            value={initialValue}
            inputIconSize={getInputIconSize()}
            inputClassName={isExpandableType() ? `expandable-input` : ``}
            handleSpecificBlur={isExpandableType()}
          />
          {!isExpandableType() ? getSearchIcon() : ''}
        </div>
      );
    };

    const getInputIconSize = (): IconSize => {
      if (isExpandableType()) {
        if (!props.isExpandedView) {
          return IconSize.SIZE_ICON_16;
        }
        return IconSize.SIZE_ICON_20;
      }
      return iconSize;
    };

    const searchBarClassName = `${styles.searchContainer} ${getClassesForSize()} ${isLarge() ? styles.bigSearchBar : ''} ${className} ${
      isExpandableType() ? 'searchContainerOptionsSocialMedia' : 'searchContainerOptions'
    }`;

    return (
      <div className={searchBarClassName}>
        {getInputFieldMarkup()}
        {props.dropdown
          ? React.cloneElement(props.dropdown, {
              dropdownClasses: `${props.dropdown.props.dropdownClasses} ${styles.dropdown}`,
            })
          : null}
      </div>
    );
  }
);
